<template>
  <div>
    {{fileList.length? '' : '暂无图片数据'}}
    <el-upload v-bind="$attrs" v-on="$listeners" class="upload-demo" :class="{ buttonDisabled: disabled }" :httpRequest="uploadFile" action="/" multiple :file-list="files" :before-remove="beforeRemove" :before-upload="breforeUpload" :on-remove="removeFile" :on-preview="getFile" :disabled="disabled" :accept="acceptValue" ref="elupload" list-type="picture-card">
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="removeFile(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <el-button size="small" type="primary" :loading="loading">{{
        loading ? "上传中" : "点击上传"
      }}</el-button>

    </el-upload>
    <el-dialog :visible.sync="dialogVisible" center title="图片查看" append-to-body>
      <template slot="title">
      </template>

      <el-image-viewer v-if="dialogVisible" :zIndex="8000" :on-close="closeViewer" :url-list="[img]" index="9999" />

    </el-dialog>
  </div>
</template>

<script >
import Sortable from "sortablejs";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { apiUploadFilePost } from "@/api";

import ImageCompressor from "image-compressor.js";
import { fileTypeValidate, acceptValue } from "@/utils/fileTypeValidation";
export default {
  name: "FormWechatFileList",
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      // 1.单个图片
      // 2。多个图片上传
      type: String,
      default: "1",
    },
  },
  components: { ElImageViewer },
  data() {
    return {
      acceptValue,
      msg: "",
      loading: false,
      failed: false,
      dialogVisible: false,
      img: "",
    };
  },
  mounted() {
    this.initDragSort();
  },
  created() {},
  computed: {
    files: {
      get() {
        // console.log("this.fileList", this.fileList);
        return this.fileList || [];
      },
      set(e) {
        console.log(123, e);
        this.$emit("input", e);
      },
    },
    tips() {
      if (!this.value) {
        return true;
      }
      return false;
    },
  },
  methods: {
    initDragSort() {
      // console.log("触发交换位置");
      const el = this.$refs.elupload.$el.querySelectorAll(".el-upload-list")[0];
      Sortable.create(el, {
        onEnd: ({ oldIndex, newIndex }) => {
          // 交换位置
          const arr = this.fileList;
          const page = arr[oldIndex];
          arr.splice(oldIndex, 1);
          arr.splice(newIndex, 0, page);
        },
      });
    },
    compressFile(file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6, //压缩质量
          checkOrientation: false, //图片翻转，默认为false
          success(result) {
            // resolve(result);
            let files = new File([result], file.name, { type: file.type });
            resolve(files);
          },
          error(e) {
            reject();
          },
        });
      });
    },
    handleDownload({ url }) {
      window.open(url);
    },
    getFile(e) {
      window.open(e.url);
    },
    handlePictureCardPreview({ url }) {
      this.img = url;

      this.dialogVisible = true;
    },
    closeViewer() {
      this.dialogVisible = false;
    },

    async uploadFile(e) {
      // if()
      this.files = [...this.files];
      const type = fileTypeValidate(e.file.name);

      this.loading = true;
      console.log("图片上传", e);

      const file = await this.compressFile(e.file);

      const formData = new FormData();
      formData.append("photo", file);

      //   formData.append("type", type);
      // this.files.push({
      //   url: "https://yidaoju.oss-cn-guangzhou.aliyuncs.com/20230530004331-6474d633b8cdd.jpg",
      //   name: "图片",
      // });
      const res = await this.$Api.file(formData);
      console.log("上传成功", res);

      this.files.push({
        url: res.data.url,
        name: e.file.name,
        // ...e.file,
      });
      this.files = [...this.files];
      this.loading = false;
      console.log("this.files", this.files);
    },
    breforeUpload(file) {
      const type = fileTypeValidate(file.name);
      console.log("上传拦截类型", type);
      if (!type) {
        this.failed = true;
        this.$notified.error({
          message: "上传失败，不支持的文件类型",
        });
      }
      console.log("Boolean(type);", Boolean(type));
      return Boolean(type);
    },
    beforeRemove(file, fileList) {
      if (this.failed) {
        this.failed = false;
        return;
      }
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    removeFile({ uid, name }) {
      console.log("e", uid);
      console.log("this.files", this.files);
      if (this.failed) {
        this.failed = false;
        return;
      }
      this.$confirm(`确定移除 ${name || "该图片"}？`).then((res) => {
        this.files = this.files.filter((i) => {
          return i.uid != uid;
        });
        // this.files = this.files;
        console.log("this.files ", this.files);
      });
      //   this.files = this.files.filter((i) => {
      //     return false;
      //   });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__header {
  display: none;
}
.buttonDisabled {
  &::v-deep {
    & > div {
      display: none;
    }
  }
}
::v-deep .el-upload-list__item {
  width: 110px;
  height: 110px;
}
::v-deep .upload-demo {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-upload-list__item {
  transition: none;
}
</style>