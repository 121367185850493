export const isImg = filename => {
  var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const isVideo = filename => {
  var re = /(\.mp4|\.flv|\.rmvb|\.mkv)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const isWord = filename => {
  var re = /(\.docx|\.docm|\.dotx|\.dotm|\.doc)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const isExcel = filename => {
  var re = /(\.xl|\.xlsx|\.xlsm|\.xlsb|\.xlam|\.xltx|\.xltm|\.xls|\.xlt)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const isPPT = filename => {
  var re = /(\.pptx|\.ppt|\.pptm|\.ppsx|\.pps|\.ppsm|\.potx|\.pot|\.potm)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const isPDF = filename => {
  var re = /(\.pdf)$/i;
  return Boolean(re.exec(filename.toLowerCase()));
};

export const acceptValue =
  ".pptx,.ppt,.pptm,.ppsx,.pps,.ppsm,.potx,.pot,.potm.pptx,.ppt,.pptm,.ppsx,.pps,.ppsm,.potx,.pot,.potm,.jpg,.jpeg,.bmp,.gif,.png,.mp4,.flv,.rmvb,.mkv,.docx,.docm,.dotx,.dotm,.doc,.xl,.xlsx,.xlsm,.xlsb,.xlam,.xltx,.xltm,.xls,.xlt";
export const acceptVideo = '.mp4,.qlv,.qsv,.oga,.flv,.avi,.wmv,.rmvb'
export const fileTypeValidate = filename => {
  if (isImg(filename)) {
    return "image";
  }
  if (isVideo(filename)) {
    return "video";
  }
  if (isWord(filename)) {
    return "word";
  }
  if (isExcel(filename)) {
    return "excel";
  }
  if (isPPT(filename)) {
    return "ppt";
  }
  if (isPDF(filename)) {
    return "pdf";
  }
  return false;
};
